/* eslint-disable */

/**
 * IE Banner
 * 
 * The syntax of the following JS has to be compatible with IE Browsers.
 * IE doesn't work properly (or not at all) with ES6 so we have to disable ES-linting for the whole code block.
 */
var $banners = document.querySelectorAll('.ie-banner');

var showBanner = function($banner) {
  $banner.classList.remove('ie-banner--hidden');
}

var hideBanner = function($banner) {
  var bannerHeight = $banner.offsetHeight;

  $banner.style.height = bannerHeight + 'px'; // eslint-disable-line
  $banner.classList.add('ie-banner--hidden');

  setTimeout(function() {
    $banner.style.height = null; // eslint-disable-line
  }, 10);
}

if ($banners) {
  for (var i = 0; i < $banners.length; i++) {
    // Check if browser is IE
    if (window.document.documentMode) {
      showBanner($banners[i]);
    }

    var $closeButton = $banners[i].querySelector('.ie-banner__close-button');

    if ($closeButton) {
      $closeButton.addEventListener('click', function() {
        hideBanner($banners);
      });
    }
  };
};
/* eslint-enable */
